import Cookies from 'js-cookie';

import { getTrafficSourceAndMedium } from 'dt-helpers/website/traffic-tracking';

const useAffiliateDetails = () => {
  const setAffiliateDetails = () => {
    const prevAffiliateDetails = Cookies.get('affiliateDetails');
    const searchParams = new URLSearchParams(window.location.search);
    const affiliateId = searchParams.get('affiliate_id');

    if (affiliateId || !prevAffiliateDetails) {
      const referrer = window.document?.referrer;
      const utmSource = searchParams.get('utm_source');
      const utmCampaign = searchParams.get('utm_campaign');
      const utmMedium = searchParams.get('utm_medium');
      const utmTerm = searchParams.get('utm_term');
      const utmContent = searchParams.get('utm_content');
      const { medium, source } = getTrafficSourceAndMedium(
        referrer ?? '',
        searchParams
      );

      const affiliateDetails = {
        utmCampaign: utmCampaign,
        utmSource: utmSource ? utmSource : source,
        affiliateId: affiliateId,
        utmMedium: utmMedium ? utmMedium : medium,
        utmTerm,
        utmContent,
      };

      Cookies.set('affiliateDetails', JSON.stringify(affiliateDetails), {
        expires: 30,
      });
    }
  };

  if (typeof window !== 'undefined') {
    setAffiliateDetails();
  }
};

export default useAffiliateDetails;
