import Image from 'next/image';
import Link from 'next/link';

import { FlexBox } from '../Flex/flex-box';

export default function OfficeAdress() {
  const socialMedia = [
    {
      logo: `${process.env.CONTENT_BASE_URL}/social-media/instagramLogo.webp`,
      link: 'https://www.instagram.com/designtemplateio/',
    },
    {
      logo: `${process.env.CONTENT_BASE_URL}/social-media/pinterestLogo.webp`,
      link: 'https://in.pinterest.com/datadoographics/',
    },
    {
      logo: `${process.env.CONTENT_BASE_URL}/social-media/linkedinLogo.webp`,
      link: 'https://in.linkedin.com/showcase/designtemplate/',
    },
    {
      logo: `${process.env.CONTENT_BASE_URL}/social-media/facebookLogo.webp`,
      link: 'https://www.facebook.com/designtemplate9',
    },
    {
      logo: `${process.env.CONTENT_BASE_URL}/social-media/x-social-media-logo-icon 1.webp`,
      link: 'https://twitter.com/Doographics1',
    },
  ];

  return (
    <FlexBox
      width={'100%'}
      justifyContent={'space-between'}
      flexWrap={'wrap'}
      gap={2}
    >
      <FlexBox flexDirection={'column'} gap={1}>
        <p>Copyright © Doographics Private Ltd. All Rights Reserved.</p>
      </FlexBox>
      <FlexBox gap={'0.8rem'}>
        {socialMedia.map((item, index) => (
          <Link href={item.link} key={index}>
            <Image
              unoptimized
              src={item.logo}
              alt="social-media"
              key={index}
              width={24}
              height={24}
              style={{
                mixBlendMode: 'multiply',
              }}
            />
          </Link>
        ))}
      </FlexBox>
    </FlexBox>
  );
}
