import { requestor } from 'dt-helpers/api/requestor';
import {
  AllCollectionResponse,
  CollectionResponse,
  CollectionResponseData,
  SaveToCollectionResponse,
  SaveToCollectionResponseData,
  SingleCollectionResponse,
} from 'dt-types/response-types/collections';

export const getCollections = async (): Promise<AllCollectionResponse> => {
  try {
    const response = await requestor.get<AllCollectionResponse>('/collection');
    return response as AllCollectionResponse;
  } catch (error) {
    console.error('Error fetching order:', error);
    throw error;
  }
};

export const getCollectionNames = async (): Promise<
  CollectionResponseData[]
> => {
  try {
    const response =
      await requestor.get<CollectionResponse>(`/collection/name`);
    return response.data as CollectionResponseData[];
  } catch (error) {
    console.error('Error fetching CollectionNames:', error);
    throw error;
  }
};

export const deleteSingleCollection = async (
  collection_id: number,
  id: string
) => {
  try {
    const response = await requestor.delete<any>(
      `/collection/collection-items?collectionId=${collection_id}&itemId=${id}`
    );
    return response.data;
  } catch (error) {
    console.error('Error Deleting Collection!..:', error);
    throw error;
  }
};

export const saveToExistingCollection = async (
  collectionId: string,
  id: string
): Promise<SaveToCollectionResponseData> => {
  try {
    const response = await requestor.post<SaveToCollectionResponse>(
      `/collection/collection-items`,
      {
        collection_id: collectionId,
        item_id: id,
      }
    );
    return response.data as SaveToCollectionResponseData;
  } catch (error) {
    console.error('Error Deleting Collection!..:', error);
    throw error;
  }
};

export const getSingleCollections = async (
  id: string
): Promise<SingleCollectionResponse> => {
  try {
    const response = await requestor.get<SingleCollectionResponse>(
      `/collection/collection-items/${id}`
    );
    return response as SingleCollectionResponse;
  } catch (error) {
    console.error('Error fetching order:', error);
    throw error;
  }
};
