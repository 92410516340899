import { requestor } from 'dt-helpers/api/requestor';
import {
  ICountryWiseItemPriceResponse,
  ICountryWiseItemPriceResponseData,
  IPlansResponse,
} from 'dt-types/response-types/pricing';

export const getPlansByCountryCode = async (
  countryCode: string
): Promise<IPlansResponse> => {
  try {
    const response = await requestor.get<IPlansResponse>(
      `/payment-service/plans/v4/${countryCode}`
    );

    return response;
  } catch (error: any) {
    console.error('Error fetching order:', error);
    throw error;
  }
};
export const getBillingPlansByCountryCode = async (
  countryCode: string
): Promise<IPlansResponse> => {
  try {
    const response: any = await requestor.get(
      `/payment-service/plans/billing-plan/${countryCode}`
    );

    return response.data as IPlansResponse;
  } catch (error: any) {
    console.error('Error fetching plans:', error);
    throw error;
  }
};

export const getPremiumPlan = async (currency: string) => {
  try {
    const response: any = await fetch(
      `${process.env.API_BASE_URL}/payment-service/plans/premiumPlan`,
      {
        headers: {
          'x-currency': currency,
        },
      }
    );
    const plans = await response.json();

    return plans;
  } catch (error) {
    return [];
  }
};

export const getCountryWiseItemPricing = async (
  itemId: string
): Promise<ICountryWiseItemPriceResponseData> => {
  try {
    const response = (await requestor.get<ICountryWiseItemPriceResponse>(
      `/listing/items/get-item-price/${itemId}`
    )) as ICountryWiseItemPriceResponse;

    return response.data as ICountryWiseItemPriceResponseData;
  } catch (error) {
    console.error(error);
    throw new Error(`Error fetching getCountryWiseItemPricingWithError:`);
  }
};
