import { useEffect, useState } from 'react';

import { requestor } from 'dt-helpers/api/requestor';
import { TimeRemainingResponse } from 'dt-types/response-types/subscription-info';
import Cookies from 'js-cookie';
import { useQuery } from 'react-query';

export const getRemainingTime = async () => {
  const result = await requestor.get<TimeRemainingResponse>(
    'orders-service/download/time-remaining'
  );

  return result;
};

export const useCountDown = () => {
  const accesstoken = Cookies.get('accesstoken');

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [hours, setHours] = useState(0);

  const [displayTimer, setDisplayTimer] = useState(false);

  useQuery([accesstoken], getRemainingTime, {
    enabled: !!accesstoken,
    staleTime: 0,
    onSuccess: (data: TimeRemainingResponse) => {
      const eligibleForDownload = data?.data?.eligibleForDownload;

      const accountKeyTime = data?.data?.accountKey;
      const ipKeyTime = data?.data?.ipKey;

      if (!eligibleForDownload) {
        const initialSeconds =
          data?.data?.userDetails?.name === 'FREE'
            ? Math.max(accountKeyTime, ipKeyTime)
            : Math.min(accountKeyTime, ipKeyTime);

        const resetRemainingSeconds = initialSeconds % 86400;

        const resetRemainingMinutes = resetRemainingSeconds % 3600;
        const resetHours = Math.floor(resetRemainingSeconds / 3600);

        setHours(resetHours);
        setMinutes(Math.floor(resetRemainingMinutes / 60));
        setMinutes(Math.floor(resetRemainingMinutes / 60));
        setSeconds(resetRemainingMinutes % 60);

        setDisplayTimer(!eligibleForDownload);
      }
    },
    onError: (err: any) => {
      if (err?.response?.status === 401) {
        Cookies.remove('accesstoken');
      }
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else if (minutes > 0) {
        setSeconds(59);
        setMinutes(minutes - 1);
      } else if (hours > 0) {
        setSeconds(59);
        setMinutes(59);
        setHours(hours - 1);
      } else {
        setDisplayTimer(false);
        clearInterval(intervalId);
      }

      if (minutes < 0) {
        setMinutes(59);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds, minutes, hours]);

  return {
    timer: [
      ...(hours > 0 ? [{ time: hours, text: 'Hours' }] : []),
      { time: minutes, text: 'Mins' },
      { time: seconds, text: 'Secs' },
    ],
    displayTimer,
  };
};
