'use client';
import React from 'react';

import { ToastContainer } from 'react-toastify';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { useLogin } from '../../../hooks/useLogin';
import { Footer } from '../../footer';
import { HeaderNav } from '../../header-with-nav';
import { MobileNavigationBar2 } from '../../mobile-navigation';
import 'react-toastify/dist/ReactToastify.css';
import { TopBar } from '../user-dashboard/styles';

export default function HomeLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  useLogin();

  return (
    <Box position={'relative'}>
      <TopBar elevation={2}>
        <Container maxWidth="lg">
          <HeaderNav />
        </Container>
      </TopBar>
      {children}
      <MobileNavigationBar2 />
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="colored"
        style={{ transition: 'ease-out' }}
      />

      <Footer />
    </Box>
  );
}
