'use client';
import { useRouter } from 'next/navigation';

import { Item } from 'dt-types/response-types/single-item';
import usePlanDetails from 'hooks/usePlanDetails';
import DividerWithText from 'shared-ui/components/divided-with-text';
import {
  DownloadEligibleCTA,
  FreeUserCTA,
  ProMemberLimitExceed,
  PurchasedItemCTA,
} from '../cta-actions';
import DownloadSection from '../download';
import { ContentBox, InnerBox } from '../styles';
import SideBarSkeleton from './side-bar-skeleton';

interface Props {
  item: Item;
  userType: 'FREE' | 'PRO' | 'PREMIUM';
  protemplates?: Array<Item>;
  downloadEligiblity: boolean;
  downloadFile: (
    _price: string,
    _currency: string,
    _isPurchased?: boolean
  ) => void;
  isDownloadLoading: boolean;
  userMessage: string;
  isLoading?: boolean;
  isPopup?: boolean;
}

const ProductPageSidebar = ({
  item,
  userType,
  downloadEligiblity,
  downloadFile,
  isDownloadLoading,
  userMessage,
  isLoading = false,
}: Props) => {
  const isPurchased = item?.isPurchased;
  const {
    data: planDetails,
    isGettingDetails,
    priceData,
  } = usePlanDetails(item.id);

  const { push } = useRouter();
  const SideBarMapper = {
    FREE: (props: any) => <FreeUserCTA {...props} />,
    PRO: (props: any) => <ProMemberLimitExceed {...props} />,
    PREMIUM: (props: any) => <DownloadEligibleCTA {...props} />,
    PURCHASED: (props: any) => <PurchasedItemCTA {...props} />,
  };
  const {
    converted_price: convertedPrice,
    currency,
    symbol,
    discount_price: discountedPrice,
  } = priceData ?? item;

  const isExhausted = !(
    downloadEligiblity &&
    (userType?.toLowerCase() === 'premium'
      ? true
      : item?.type?.toLowerCase() === userType?.toLowerCase())
  );

  const Component = SideBarMapper[isPurchased ? 'PURCHASED' : userType];

  const handleDownloadFile = () => {
    downloadFile(convertedPrice, currency, isPurchased);
  };

  const redirectToPricingPage = () => {
    push('/pricing');
  };

  if (!planDetails || isGettingDetails || isLoading) {
    return <SideBarSkeleton />;
  }

  const startsText = planDetails
    ? `Starts From ${planDetails?.currency} @${planDetails?.symbol}${(
        planDetails?.price / 12
      )?.toFixed(2)}/Month`
    : 'Starts From INR @₹249/Month';

  return (
    <ContentBox>
      <InnerBox>
        <Component
          heading="Become a premium member and get access to"
          headingFocusText="Unlimited Downloads"
          buttonText={startsText}
          handleClick={redirectToPricingPage}
          additionalText={startsText}
          additionalLink="/pricing"
          additionalLinkText="Learn More"
          exhausted={isExhausted}
          limitExceededText={userMessage}
          downloadFile={downloadFile}
          currency={currency}
          discountPrice={discountedPrice}
          isPurchased={isPurchased}
        />
      </InnerBox>
      {!isPurchased && (userType !== 'PREMIUM' || isExhausted) && (
        <>
          {userType.toString() === 'FREE' && <DividerWithText text="OR" />}
          <DownloadSection
            isExhausted={isExhausted}
            symbol={symbol}
            currency={currency}
            price={convertedPrice}
            discountPrice={discountedPrice}
            limitExceededText={userMessage}
            downloadFile={handleDownloadFile}
            isDownloadLoading={isDownloadLoading}
            item={item}
            userType={userType}
          />
        </>
      )}
    </ContentBox>
  );
};

export default ProductPageSidebar;
