'use client';
import Image from 'next/image';

import { Box, Stack, Typography, styled } from '@mui/material';
import { FlexRowCenter } from '../../flex-box';
import { typography } from '../../../theme/typography';


interface Card2Props {
  imageSrc?: string;
  height?: string;
  width?: string;
  title?: string;
  descrition?: string;
  alignment: 'center' | 'justify';
}

const StyledImage = styled(Image)(({ theme }) => ({
  width: '8rem',
  height: '8rem',
  objectFit: 'contain',
  [theme.breakpoints.down('md')]: {
    width: '10rem',
    height: '9rem',
  },
}));

const Card2 = ({
  imageSrc,
  title,
  descrition,
  alignment = 'justify',
}: Card2Props) => {
  return (
    <>
      <Stack
        sx={{
          background: 'transparent',
          padding: '2%',
          height: '100%',
        }}
        width={{ sm: '25rem', xs: '70%' }}
        justifyContent={'stretch'}
      >
        <Stack
          alignItems={'center'}
          gap={1}
          justifyContent={'stretch'}
          height={'100%'}
        >
          <FlexRowCenter>
            <Box>
              {imageSrc && (
                <StyledImage
                  src={imageSrc}
                  alt="Rectangle"
                  height={0}
                  width={0}
                  loading="lazy"
                />
              )}
            </Box>
          </FlexRowCenter>
          <Typography
            fontFamily={typography.fontFamily}
            fontSize={{ md: '1.25rem', xs: '1rem' }}
            fontStyle="normal"
            fontWeight={500}
            textTransform={'capitalize'}
          >
            {title}
          </Typography>
          <Typography
            fontFamily={typography.fontFamily}
            fontSize={{ md: '0.9rem', xs: '0.9rem' }}
            fontStyle="normal"
            fontWeight={500}
            textTransform={'capitalize'}
            color={'#7D7373'}
            textAlign={alignment}
          >
            {descrition}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default Card2;
