import { Stack } from '@mui/material';

import FAQ from '../../faq/FAQ';

const FAQSection = () => {
  return (
    <Stack
      spacing={1}
      justifyContent={'center'}
      alignItems={'center'}
      paddingY={'2%'}
    >
      <FAQ />
    </Stack>
  );
};

export default FAQSection;
