import useDialougeToggler from '../../hooks/useDialougeToggler';
import { DialogDrawer } from '../dialog-drawer';
import LoginForm from '../login-form';
import RegistrationForm from '../registration-form';
import VerificationForm from '../verification-form';

export const DialougeHandler = () => {
  const { state } = useDialougeToggler();
  const componentMapper: any = {
    RegistrationForm: <RegistrationForm />,
    LoginForm: <LoginForm />,
    VerificationForm: <VerificationForm />,
  };

  return (
    state?.toggle &&
    state.toggle.toggled && (
      <DialogDrawer dialogOpen={state.toggle.toggled} toggleDialog={() => {}}>
        {componentMapper[state.toggle.element]}
      </DialogDrawer>
    )
  );
};
