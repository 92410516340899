import { useSwiper } from 'swiper/react';

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

import { themeColors } from '../../theme/theme-colors';

const PrevNavigationButton = ({ count }: { count: number }) => {
  const swiper = useSwiper();

  const goPreviousSlide = () => {
    if (swiper.isBeginning) {
      swiper.slideTo(count);
    } else {
      swiper.slidePrev();
    }
  };

  return (
    <>
      <ArrowCircleLeftOutlinedIcon
        sx={{
          color: '#6E5BE4',
          position: 'absolute',
          cursor: 'pointer',
          zIndex: 1000,
          borderColor: 'blue',
          background: '#fff',
          borderRadius: '50px',
          left: 0,
          display: !count ? 'none' : 'block',
          top: '40%',
          '&:hover': {
            color: themeColors?.primary[500],
          },
        }}
        fontSize="large"
        onClick={goPreviousSlide}
      />
    </>
  );
};

export default PrevNavigationButton;
