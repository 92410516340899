import { useRouter } from 'next/navigation';

import { Box, Button, Container, Grid } from '@mui/material';

import StarIcon from '../icons/star';
import {
  PromotionAbsoluteLeftCircle,
  PromotionAbsoluteRightCircle,
  PromotionContainer,
  PromotionDataContainer,
  PromotionHeading,
  StyledImage,
} from './style';

const benefits = [
  'Personal Use License',
  '24/7 customer support',
  'Unlimited Pro design files',
  'Content On Demand',
  '80 premium items per month',
  'Unlimited Free design files',
  '20 premium items daily',
  'Exclusive content',
  '3D And CGI VFX Assets',
];

const PromotionCard = () => {
  const { push } = useRouter();

  const redirectToPricing = () => {
    push('/pricing');
  };

  return (
    <PromotionContainer>
      <PromotionAbsoluteLeftCircle />
      <Container maxWidth='lg' sx={{ py: 1 }}>
        <PromotionDataContainer>
          <Box
            display='flex'
            flex={3}
            flexDirection='column'
            justifyContent='center'
          >
            <PromotionHeading>
              create a design in <span>No time</span> with our premium design
              assets
            </PromotionHeading>
            <Grid
              container
              display='flex'
              columnSpacing={2}
              pt={3}
              marginX='auto !important'
            >
              {benefits.map((benefit, index) => (
                <Grid
                  item
                  xs={12}
                  md={4}
                  py={1}
                  display='flex'
                  key={index}
                  alignItems='center'
                  gap={1}
                >
                  <StarIcon
                    sx={{
                      fill: 'linear-gradient(180deg, #FFD401 0%, #ECC400 100%)',
                    }}
                  />
                  <p>{benefit}</p>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box
            display='flex'
            flex={1}
            justifyContent='center'
            alignItems='center'
            position={'relative'}
          >
            <StyledImage
              src={`${process.env.CONTENT_BASE_URL}/horizontalCard.webp`}
              width={0}
              height={0}
              alt='horizontalCard.webp'
            />
          </Box>
        </PromotionDataContainer>
        <div style={{ textAlign: 'center', paddingBottom: 20 }}>
          <Button
            variant='contained'
            size='large'
            sx={{
              width: '16.25rem',
              borderRadius: 'var(--Corner-redius-4, 10px)',
              background: '#6E5BE4',
              boxShadow: '0px 8px 24px 0px rgba(0, 0, 0, 0.15)',
              color: 'white',
              ':hover': {
                backgroundColor: '#5D4CC6',
              },
            }}
            onClick={redirectToPricing}
          >
            Subscribe To Premium
          </Button>
        </div>
      </Container>
      <PromotionAbsoluteRightCircle />
    </PromotionContainer>
  );
};

export default PromotionCard;
