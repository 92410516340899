'use client';
import { useState } from 'react';

import { useQuery } from 'react-query';

import { useRouter } from 'next/navigation';

import EastIcon from '@mui/icons-material/East';
import { Box, Chip, Container, Grid, Stack, Typography } from '@mui/material';

import ProductCard from 'components/product-card';
import { getAdvancedSearchResult } from 'dt-utils/__api__/tag-item';
import { FlexBox, FlexRowCenter } from 'shared-ui/components/flex-box';
import SwipeableTextMobileStepper from 'shared-ui/components/home-sections/swipableviews/SwipeableTextMobileStepper';
import { themeColors } from 'shared-ui/theme/theme-colors';
import { typography } from 'shared-ui/theme/typography';
import { StyledGrid, StyledTypography } from './style';

interface TemplateSectionProps {
  title: string;
  subtitle: string;
  template?: string;
  cardData?: any;
  isTemplate: boolean;
  limit?: number;
}

const TemplateSection = ({
  title,
  subtitle,
  cardData,
  template,
  isTemplate,
  limit = 6,
}: TemplateSectionProps) => {
  const router = useRouter();
  const [active, setActive] = useState('after-effects-templates');
  const [slug, setSlug] = useState(
    template ? `${template}-after-effects-templates` : 'after-effects-templates'
  );

  const { data: { items: itemsData } = {} } = useQuery(
    ['filter', active, template],
    () => getAdvancedSearchResult({ category: slug, page: 1, limit }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 60 * 60 * 1000,
      keepPreviousData: true,
    }
  );

  const data = itemsData ?? cardData;

  const onClickHandler = async (slugname: string) => {
    setSlug(template ? `${template}-${slugname}` : slugname);
    setActive(slugname);
  };

  return (
    <FlexRowCenter>
      <Stack
        paddingTop={'2rem'}
        sx={
          !isTemplate
            ? {
                boxShadow: '0px 0px 10px 3px rgba(0, 0, 0, 0.15) inset',
                backgroundColor: !isTemplate ? '#F1EFFF' : '#FFFDFC',
              }
            : {
                backgroundColor: !isTemplate ? '#F1EFFF' : '#FFFDFC',
              }
        }
        width={'100%'}
      >
        <Container>
          <Stack
            spacing={2}
            justifyContent={{ sm: 'center', md: 'start' }}
            alignItems={{ xs: 'center', sm: 'start' }}
            marginBottom={'3rem'}
            overflow={'hidden'}
            position={'relative'}
          >
            <Stack paddingLeft={{ md: '1rem' }} width={'100%'}>
              <Typography
                fontFamily={typography.fontFamily}
                fontSize={{ md: '2rem', xs: '1.4rem' }}
                textTransform={'capitalize'}
                lineHeight={1.4}
                fontWeight={600}
                component="h2"
                color="black"
              >
                {title}
              </Typography>
              <Typography
                fontFamily={typography.fontFamily}
                fontSize={{ md: '1rem', xs: '0.8rem' }}
                textTransform={'capitalize'}
                color="#5E5E5E"
                lineHeight={'normal'}
              >
                {subtitle}
              </Typography>
            </Stack>

            {isTemplate ? (
              <>
                <FlexBox
                  width={'100%'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <FlexBox
                    gap={{ md: '1rem', xs: '0.2rem' }}
                    flexWrap={'wrap'}
                    paddingLeft={{ md: '1rem' }}
                    alignSelf={'start'}
                  >
                    <FlexBox
                      gap={{ md: '1rem', xs: '0.2rem' }}
                      alignSelf={'start'}
                    >
                      <Chip
                        label="After Effect"
                        style={
                          active === 'after-effects-templates'
                            ? { color: '#fff', backgroundColor: '#6E5BE4' }
                            : {
                                color: '#000',
                                backgroundColor: '#fff',
                                border: '1px solid #E4E6E8',
                              }
                        }
                        onClick={() =>
                          onClickHandler('after-effects-templates')
                        }
                        sx={{
                          fontSize: { xs: '0.6rem', sm: '0.8rem' },
                          padding: { xs: '0rem', md: '0.2rem' },
                          ':hover': {
                            backgroundColor: '#E8E4FF',
                          },
                        }}
                        size="medium"
                      />
                      <Chip
                        label="Premiere Pro"
                        style={
                          active === 'premiere-pro-templates'
                            ? { color: '#fff', backgroundColor: '#6E5BE4' }
                            : {
                                color: '#000',
                                backgroundColor: '#fff',
                                border: '1px solid #E4E6E8',
                              }
                        }
                        onClick={() => onClickHandler('premiere-pro-templates')}
                        sx={{
                          fontSize: { xs: '0.6rem', sm: '0.8rem' },
                          padding: { xs: '0rem', md: '0.2rem' },
                          ':hover': {
                            backgroundColor: '#E8E4FF',
                          },
                        }}
                        size="medium"
                      />
                    </FlexBox>

                    <Chip
                      label="Graphic Design"
                      style={
                        active === 'images'
                          ? { color: '#fff', backgroundColor: '#6E5BE4' }
                          : {
                              color: '#000',
                              backgroundColor: '#fff',
                              border: '1px solid #E4E6E8',
                            }
                      }
                      onClick={() => onClickHandler('images')}
                      sx={{
                        fontSize: { xs: '0.6rem', sm: '0.8rem' },
                        padding: { xs: '0rem', md: '0.2rem' },
                        ':hover': {
                          backgroundColor: '#E8E4FF',
                        },
                      }}
                      size="medium"
                    />
                    <Chip
                      label="Motion Graphics"
                      style={
                        active === 'videos'
                          ? { color: '#fff', backgroundColor: '#6E5BE4' }
                          : {
                              color: '#000',
                              backgroundColor: '#fff',
                              border: '1px solid #E4E6E8',
                            }
                      }
                      onClick={() => onClickHandler('videos')}
                      sx={{
                        fontSize: { xs: '0.6rem', sm: '0.8rem' },
                        padding: { xs: '0rem', md: '0.2rem' },
                        ':hover': {
                          backgroundColor: '#E8E4FF',
                        },
                      }}
                      size="medium"
                    />
                  </FlexBox>
                  <StyledTypography onClick={() => router.push(slug)}>
                    See more{' '}
                    <EastIcon
                      sx={{
                        verticalAlign: 'middle',
                      }}
                    />
                  </StyledTypography>
                </FlexBox>
                {template === 'free' ? (
                  <Box width={'100%'}>
                    <FlexBox
                      gap={'1rem'}
                      width={'100%'}
                      sx={{
                        overflowX: 'scroll',
                        paddingY: '2%',
                        paddingLeft: '1%',
                      }}
                    >
                      {data?.map((item: any) => (
                        <Grid
                          display="flex"
                          flexGrow={1}
                          justifyContent="center"
                          key={item.id}
                          style={{ paddingLeft: '0' }}
                        >
                          <ProductCard item={item} />
                        </Grid>
                      ))}
                      <StyledGrid
                        display="none"
                        flexGrow={1}
                        justifyContent="center"
                        style={{ paddingLeft: '0' }}
                        alignItems={'center'}
                        minWidth={'7rem'}
                      >
                        <Typography
                          sx={{
                            color: themeColors.primary.main,
                            marginLeft: 'auto',
                            verticalAlign: 'middle',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            router.push(
                              template === 'free'
                                ? `/${template}-${active}`
                                : `/${active}`
                            )
                          }
                        >
                          View more{' '}
                          <EastIcon
                            sx={{
                              verticalAlign: 'middle',
                            }}
                          />
                        </Typography>
                      </StyledGrid>
                    </FlexBox>
                  </Box>
                ) : (
                  <FlexBox justifyContent={'center'} alignItems={'center'}>
                    <Grid container direction="row" spacing={2}>
                      {data?.map((item: any) => (
                        <Grid
                          display="flex"
                          flexGrow={1}
                          justifyContent="center"
                          item
                          md={6}
                          lg={4}
                          sm={6}
                          xs={12}
                          key={item.id}
                        >
                          <ProductCard item={item} />
                        </Grid>
                      ))}
                      <StyledGrid
                        display="none"
                        flexGrow={1}
                        justifyContent="center"
                        style={{ paddingLeft: '0' }}
                        alignItems={'center'}
                        minWidth={'6rem'}
                      >
                        <Typography
                          sx={{
                            color: themeColors.primary.main,
                            marginInline: 'auto',
                            verticalAlign: 'middle',
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            router.push(
                              template === 'free'
                                ? `/${template}-${active}`
                                : `/${active}`
                            )
                          }
                        >
                          View more{' '}
                          <EastIcon
                            sx={{
                              verticalAlign: 'middle',
                            }}
                          />
                        </Typography>
                      </StyledGrid>
                    </Grid>
                  </FlexBox>
                )}
              </>
            ) : (
              <>
                <Typography
                  color={themeColors.primary.main}
                  textAlign={'right'}
                  width={'100%'}
                  style={{
                    cursor: 'pointer',
                    marginTop: '0.1rem',
                  }}
                  onClick={() => router.push('/wedding')}
                >
                  See more{' '}
                  <EastIcon
                    sx={{
                      verticalAlign: 'middle',
                    }}
                  />
                </Typography>
                <FlexBox
                  justifyContent={{ sm: 'start', xs: 'center' }}
                  style={{
                    marginTop: '0.4rem',
                  }}
                >
                  <SwipeableTextMobileStepper cardData={cardData} />
                </FlexBox>
              </>
            )}
          </Stack>
        </Container>
      </Stack>
    </FlexRowCenter>
  );
};

export default TemplateSection;
