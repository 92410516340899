import { requestor } from 'dt-helpers/api/requestor';
import { TimeRemainingResponse } from 'dt-types/response-types/subscription-info';

export const fetchUserData = async (): Promise<TimeRemainingResponse> => {
  try {
    const result = await requestor.get<TimeRemainingResponse>(
      'orders-service/download/time-remaining'
    );

    return result;
  } catch (error: any) {
    throw new Error(`error timeRemaining`);
  }
};
