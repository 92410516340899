import { styled } from '@mui/material';
import Image from 'next/image';

export const PromotionContainer = styled('div')(() => ({
  background: '#FFF',
  backdropFilter: 'blur(52.95000076293945px)',
  width: '100vw',
  display: 'relative',
  overflow: 'hidden',
}));

export const PromotionAbsoluteLeftCircle = styled('div')(() => ({
  position: 'absolute',
  left: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  width: 404,
  height: 404,
  borderRadius: 404,
  background: 'rgba(206, 199, 255, 0.50)',
  filter: 'blur(250px)',
  zIndex: 0,
}));

export const PromotionAbsoluteRightCircle = styled('div')(() => ({
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  width: 404,
  height: 404,
  borderRadius: 404,
  background: 'rgba(206, 199, 255, 0.50)',
  filter: 'blur(250px)',
  zIndex: 0,
}));

export const PromotionDataContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: '1rem 0',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
  },
}));

export const PromotionHeading = styled('h2')(({ theme }) => ({
  color: '#404040',
  textAlign: 'center',
  fontSize: 42,
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  textTransform: 'capitalize',
  '& span': {
    background: 'linear-gradient(90deg, #6E5BE4 52.42%, #FF4790 74.23%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textTransform: 'capitalize',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 30,
  },
}));

export const StyledImage = styled(Image)(({ theme }) => ({
  width: '32rem',
  height: '20rem',
  [theme.breakpoints.down('xl')]: {
    height: '15rem',
    width: '26.5rem',
  },
  [theme.breakpoints.down('md')]: {
    height: 'auto',
    width: '80%',
  },
  aspectRatio: '16/9',
}));
