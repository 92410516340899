import React from 'react';

import { Box } from '@mui/material';

import { FlexRowCenter } from '../../flex-box';

interface CenterWrapperProps {
  children?: React.ReactNode;
}

const CenterWrapper = ({ children }: CenterWrapperProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        background: '#FFFDFC',
      }}
    >
      <FlexRowCenter
        alignItems={'center'}
        justifyContent={'center'}
        height={'100%'}
        width={'100%'}
      >
        {children}
      </FlexRowCenter>
    </Box>
  );
};

export default CenterWrapper;
