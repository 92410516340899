import { FC } from 'react';

import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Grid from '@mui/material/Grid';

import BazaarCard from '../BazaarCard';
import FlexRowCenter from '../flex-box/flex-row-center';
import { NavLink } from '../nav-link';
import CategoryList from './category-list';
import { MenusContainer, Wrapper } from './styles';
import { NavWithChild } from './types';

interface Props {
  title: string;
  menuList?: NavWithChild[];
  path: string;
}

const gridSize = (length: number) => {
  if (length === 1) {
    return 12;
  }

  if (length === 2) {
    return 6;
  }

  if (length === 3) {
    return 4;
  }

  if (length === 4) {
    return 3;
  }

  return 3;
};

const MegaMenu: FC<Props> = ({ title, menuList, path }) => {
  const grid = gridSize(menuList?.length ?? 0);

  const STYLE = {
    py: 2,
    ':nth-of-type(even)': { backgroundColor: 'grey.100' },
  };

  return (
    <Wrapper>
      <FlexRowCenter alignItems='flex-end' gap={0.3}>
        <NavLink href={`/${path}`}>{title}</NavLink>
        {!!menuList?.length && (
          <KeyboardArrowDown sx={{ color: 'grey.500', fontSize: '1.1rem' }} />
        )}
      </FlexRowCenter>

      {!!menuList?.length && (
        <MenusContainer className='menu-list'>
          <BazaarCard
            elevation={3}
            sx={{
              mt: 1.5,
              overflow: 'hidden',
              minWidth: menuList?.length * 175,
              maxWidth: 800,
              ml: {
                xs: 14,
                lg: title.toLowerCase() === 'templates' ? 14 : 8,
                xl: 0,
              },
            }}
          >
            <Grid container>
              {menuList?.map((item, i) => (
                <Grid item md={grid} sx={STYLE} key={item.title}>
                  <CategoryList
                    category={item}
                    key={item.title + i}
                    totalItems={menuList.length}
                  />
                </Grid>
              ))}
            </Grid>
          </BazaarCard>
        </MenusContainer>
      )}
    </Wrapper>
  );
};

export default MegaMenu;
