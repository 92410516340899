import { Fragment } from 'react';

import { Typography } from '@mui/material';

import { FlexBox } from '../Flex/flex-box';
import { SubItems } from './style';

export default function HomeSection() {
  return (
    <Fragment>
      <FlexBox flexDirection={'column'} alignSelf="start" gap={1}>
        <Typography
          variant="h3"
          sx={{
            background: 'linear-gradient(90deg, #623BE0 5.14%, #E52775 99.7%)',
            backgroundClip: 'text',
            color: 'transparent',
            fontSize: '20px !important',
          }}
        >
          Designtemplate
        </Typography>
        {options.map((item) => (
          <SubItems key={item.name} fontSize={'0.85rem'} fontWeight={400}>
            <a href={item.url}>{item.name}</a>
          </SubItems>
        ))}
      </FlexBox>
    </Fragment>
  );
}

const options = [
  {
    name: 'Home',
    url: '/',
  },
  {
    name: 'Pricing',
    url: '/pricing',
  },
  {
    name: 'Content on Demand',
    url: 'https://forms.gle/qeKYNfdbaEHMtoQV8',
  },
];
