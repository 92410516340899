import { useState } from 'react';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { CircularProgress, Typography } from '@mui/material';

import { FlexBox } from '../flex-box';
import { themeColors } from '../../theme/theme-colors';

const SuggestedCollection = ({
  createCollection,
  collection,
}: {
  createCollection: (_collectionTitle: string) => void;
  collection: any;
}) => {
  const [isLoading, setIsloading] = useState(false);

  return (
    <>
      <FlexBox
        gap={1}
        padding={'0.7rem'}
        sx={{
          borderRadius: '0.75rem',
          backgroundColor: '#FFF',
          border: '0.7px solid #6E5BE4',
          ':hover': {
            cursor: 'pointer',
            backgroundColor: '#E9E5FF',
            border: ' 0.7px solid #6E5BE4',
          },
        }}
        alignItems={'center'}
        justifyContent={'space-between'}
        key={collection}
      >
        <FlexBox alignItems={'center'} gap={1}>
          <AddTwoToneIcon />{' '}
          <Typography textTransform={'capitalize'}>{collection}</Typography>
        </FlexBox>
        <Typography
          sx={{
            background: 'transparent',
            borderRadius: '1rem',
            fontSize: '0.8rem',
            paddingY: '0.2rem',
            paddingRight: '0.5rem',
            color: '#6E5BE4',
            textDecoration: 'underline',
            ':hover': {
              cursor: 'pointer',
              color: themeColors.info[900],
            },
          }}
          onClick={async () => {
            setIsloading(true);
            await createCollection(collection);
            setIsloading(false);
          }}
        >
          {!isLoading ? 'Create' : <CircularProgress size={'15px'} />}
        </Typography>
      </FlexBox>
    </>
  );
};

export default SuggestedCollection;
