'use client';

import TemplateSection from 'components/home-sections/template-section';
import { FlexBoxCentered } from 'shared-ui/components/Flex/flex-box';
import BlogSection from 'shared-ui/components/home-sections/blog-section';
import CenterWrapper from 'shared-ui/components/home-sections/center-wrapper';
import FAQSection from 'shared-ui/components/home-sections/faq-section';
import FeaturesSection from 'shared-ui/components/home-sections/features-section';
import TrustedPartners from 'shared-ui/components/home-sections/trusted-partners';
import VideoWithSearchBar from 'shared-ui/components/home-sections/video-with-searchBar';
import PromotionCard from 'shared-ui/components/promotion-card';
import SharkTankBanner from 'shared-ui/components/shark-tank-banner';

export default function Index({ cardData }: { cardData: any }) {
  return (
    <>
      <VideoWithSearchBar />
      <TemplateSection
        isTemplate={true}
        title="new release"
        subtitle="Experience daily creativity with designTemplate.io continuous stream of new design assets"
        cardData={cardData && cardData[0]?.items}
      />
      <TemplateSection
        template="free"
        isTemplate={true}
        title="Explore Our Free Templates"
        subtitle="Access our wide range of free, high-quality templates to fuel your creativity and projects, exclusively at designTemplate.io."
        cardData={cardData && cardData[1]?.items}
      />
      <TemplateSection
        template="free"
        isTemplate={false}
        title="Wedding Collection"
        subtitle="Craft unforgettable wedding invitations with our designer-exclusive assets."
        cardData={cardData && (cardData[2]?.tagItems || cardData[2]?.items)}
      />

      <CenterWrapper>
        <FeaturesSection />
      </CenterWrapper>

      <CenterWrapper>
        <SharkTankBanner />
      </CenterWrapper>

      <PromotionCard />

      <BlogSection blogData={cardData && cardData?.[3]} />

      <FAQSection />

      <FlexBoxCentered>
        <TrustedPartners />
      </FlexBoxCentered>
    </>
  );
}
