'use client';

import { FC, Fragment, PropsWithChildren, useState } from 'react';

import Cookies from 'js-cookie';

import { useRouter } from 'next/navigation';

import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';

import useAuth from '../../hooks/useAuth';
import useDialougeToggler from '../../hooks/useDialougeToggler';
import { StyledBox, StyledDrawer, StyledNavLink, Wrapper } from './styles';
import Navigation from '../layouts/user-dashboard/navigation';

/**
 * Difference between MobileNavigationBar and MobileNavigationBar2
 * 1. In the MobileNavigationBar we doesn't use conditionally render
 * 2. In the list array if doesn't exists href property then open category menus sidebar drawer in MobileNavigationBar2
 */

const MobileNavigationBar2: FC<PropsWithChildren> = () => {
  const { state } = useAuth();
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const handleDrawerClose = () => setOpen(false);
  const handleDrawerToggle = () => setOpen((state) => !state);
  const { dispatch } = useDialougeToggler();
  const list = [
    {
      title: 'Home',
      Icon: <HomeOutlinedIcon width={25} height={25} />,
      href: '/',
    },
    {
      title: 'Category',
      Icon: <CategoryOutlinedIcon width={25} height={25} />,
    },
    {
      title: 'Plans',
      Icon: <SubscriptionsOutlinedIcon width={25} height={25} />,
      href: '/pricing',
    },
    {
      title: 'Profile',
      Icon: '',
      isIcon: true,
    },
  ];

  return (
    <Box position="relative" display="flex" flexDirection="column">
      <StyledDrawer open={open} anchor="left" onClose={handleDrawerClose}>
        <Navigation drawerClose={handleDrawerClose} />
      </StyledDrawer>

      <Wrapper>
        {list.map(({ Icon, title, href, isIcon }) => {
          // LINK INNER CONTENTS

          const ICON = isIcon ? (
            <Avatar sx={{ cursor: 'pointer', height: 30, width: 30 }}>
              {state?.user.isVerified
                ? state?.user?.email?.[0]?.toUpperCase()
                : 'A'}
            </Avatar>
          ) : (
            Icon
          );

          const CONTENT = (
            <Fragment>
              <Badge color="primary">{ICON}</Badge>
              {title}
            </Fragment>
          );

          return href ? (
            <StyledNavLink key={title} href={href} prefetch={false}>
              {CONTENT}
            </StyledNavLink>
          ) : (
            <StyledBox
              key={title}
              onClick={
                title !== 'Profile'
                  ? handleDrawerToggle
                  : () => {
                      if (!Cookies.get('accesstoken')) {
                        dispatch({
                          type: 'TOGGLE',
                          payload: {
                            toggled: true,
                            element: 'LoginForm',
                            closeBtn: true,
                          },
                        });
                      } else {
                        router.push(
                          process.env.USER_DASHBOARD_URL ?? '/profile'
                        );
                      }
                    }
              }
            >
              {CONTENT}
            </StyledBox>
          );
        })}
      </Wrapper>
    </Box>
  );
};

export default MobileNavigationBar2;
