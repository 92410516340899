import { ICountryWiseItemPriceResponseData } from 'dt-types/response-types/pricing';
import {
  getCountryWiseItemPricing,
  getPremiumPlan,
} from 'dt-utils/__api__/pricing';
import Cookies from 'js-cookie';
import { useQuery } from 'react-query';

const usePlanDetails = (itemId: string) => {
  const { data: priceData, isSuccess: isCurrencySuccess } =
    useQuery<ICountryWiseItemPriceResponseData | null>({
      queryKey: ['price'],
      queryFn: () => getCountryWiseItemPricing(itemId),
      enabled: !!itemId,
      retry: false,
      onError: () => {
        return null;
      },
    });

  const { data, isLoading } = useQuery(
    ['planDetails', priceData?.currency],
    () => getPremiumPlan(priceData?.currency ?? 'USD'),
    {
      enabled: !!priceData?.currency && !!isCurrencySuccess,
      onSuccess: (data) => {
        const planDetails = {
          price: Number(data.price) / 12,
          currency: data.currency,
          symbol: data.symbol,
        };

        Cookies.set('planDetails', JSON.stringify(planDetails));
      },
      onError: () => {
        Cookies.set(
          'planDetails',
          JSON.stringify({ price: 3.99, symbol: '$', currency: 'USD' })
        );
      },
      refetchOnWindowFocus: false,
    }
  );

  return {
    data,
    isGettingDetails: isLoading,
    priceData,
  };
};

export default usePlanDetails;
