import Image from 'next/image';

import { Box, Button, Stack, Typography } from '@mui/material';

import { FlexBox } from 'shared-ui/components/flex-box';

const HorizontalCard2 = () => {
  return (
    <Stack
      alignItems={'center'}
      spacing={1}
      padding={'1rem'}
      sx={{
        width: '100%',
        height: 'auto',
        position: 'relative',

        ':before': {
          content: '""',
          zIndex: -1,
          width: '100%',
          height: '100%',
          position: 'absolute',

          top: '0px',
          left: 0,
          background:
            'url(https://designtemplate.tech/static/next/public${process.env.CONTENT_BASE_URL}/static/next/public/assets/Images/static-pages/termsofuseBg.webp)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        },
      }}
    >
      <FlexBox
        flexDirection={{
          xs: 'column-reverse',
          md: 'row',
        }}
      >
        <Stack spacing={2} padding={'5%'}>
          <Box>
            <Typography variant="h4">
              Subscribe now and get millions of resource!!!
            </Typography>
          </Box>
          <Box>
            Get access to millions of resources with a single subscription.
          </Box>
          <Box>
            <Button variant="contained" color="primary">
              Subscribe
            </Button>
          </Box>
        </Stack>
        <Box overflow={'hidden'} width={'100%'}>
          <Box display={'flex'} justifyContent={'end'}>
            <Image
              unoptimized
              src={`${process.env.CONTENT_BASE_URL}/static/next/public/assets/images/static-pages/app-thumbnail-3.webp`}
              alt="Image"
              width={500}
              height={400}
              style={{ maxWidth: '100%', display: 'block' }}
            />
          </Box>
        </Box>
      </FlexBox>
    </Stack>
  );
};

export default HorizontalCard2;
