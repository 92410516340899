'use client';

import Image from 'next/image';
import { useRouter } from 'next/navigation';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { FlexBox, FlexRowCenter } from 'shared-ui/components/flex-box';

const NotFound = () => {
  const router = useRouter();

  return (
    <FlexRowCenter px={2} minHeight="60vh" flexDirection="column">
      <Box maxWidth={320} width="100%" mb={3}>
        <Image
          alt="Not Found!"
          src={`${process.env.CONTENT_BASE_URL}/static/next/public/error.svg`}
          width={20}
          height={20}
          style={{ width: '100%', height: 'auto' }}
        />
      </Box>

      <FlexBox flexWrap="wrap" gap={2}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => router.back()}
        >
          Go Back
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => router.push('/')}
        >
          Go to Home
        </Button>
      </FlexBox>
    </FlexRowCenter>
  );
};

export default NotFound;
