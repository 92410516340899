import { IPAPIResponseData } from 'dt-types/response-types/IPAPI';
import axios from 'axios';
import Cookies from 'js-cookie';

import type { AxiosInstance, AxiosRequestConfig } from 'axios';

type ClientCookie = typeof Cookies;
class ApiClient {
  private instance: AxiosInstance;
  private cookies: ClientCookie;

  constructor(baseURL: string, cookiesStore?: ClientCookie) {
    this.instance = axios.create({
      baseURL,
      timeout: 5000,
    });
    this.initializeRequestInterceptor();
    this.initializeResponseInterceptor();
    this.cookies = cookiesStore || Cookies;
  }

  private initializeRequestInterceptor() {
    this.instance.interceptors.request.use(
      (config) => {
        const accessToken = this.cookies.get('accesstoken');
        const affiliateDetails = this.cookies.get('affiliateDetails');

        const userUniqueDeviceId = this.cookies.get('usr_token');

        if (userUniqueDeviceId && typeof userUniqueDeviceId === 'string') {
          config.headers['x-device-id'] = userUniqueDeviceId;
        }

        if (accessToken && typeof accessToken === 'string') {
          config.headers['accessToken'] = accessToken;
        }

        if (affiliateDetails && typeof affiliateDetails === 'string') {
          const parsedAffiliateDetails = JSON.parse(affiliateDetails);

          config.headers['x-utm-campaign'] =
            parsedAffiliateDetails?.utmCampaign ?? 'direct';
          config.headers['x-utm-source'] =
            parsedAffiliateDetails?.utmSource ?? 'direct';
          config.headers['x-utm-medium'] =
            parsedAffiliateDetails?.utmMedium ?? 'direct';
          config.headers['x-affiliate-id'] =
            parsedAffiliateDetails?.affiliateId ?? null;
        }

        const userData = this.cookies.get('session_id');

        if (userData && typeof userData === 'string') {
          const data = JSON.parse(userData) as IPAPIResponseData;

          config.headers['x-city'] = encodeURIComponent(data.city);
          config.headers['x-country'] = encodeURIComponent(data.country);
          config.headers['x-currency'] = encodeURIComponent(data?.currency);
          config.headers['x-zip'] = encodeURIComponent(data.zip);
          config.headers['x-state'] = encodeURIComponent(data.regionName);
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  private initializeResponseInterceptor() {
    this.instance.interceptors.response.use(
      (response) => {
        if ('remove' in this.cookies) {
          if (response?.data?.statusCode === 401) {
            this.cookies.remove('accesstoken');
          }
        }

        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.instance.get<T>(url, config);

    return response.data;
  }

  public async post<T>(
    url: string,
    body?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const response = await this.instance.post<T>(url, body, config);

    return response.data;
  }

  public async put<T>(
    url: string,
    body?: any,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const response = await this.instance.patch<T>(url, body, config);

    return response.data;
  }
  public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.instance.delete<T>(url, config);

    return response.data;
  }

  public setCookiesProvider(cookiesStore: ClientCookie) {
    this.cookies = cookiesStore || Cookies;
  }
}

export const requestor = new ApiClient(process.env.API_BASE_URL as string);
