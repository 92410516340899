import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { typography } from '../../theme/typography';
import FAQCollapse from '../collapse';

export default function FAQ() {
  return (
    <>
      <Stack
        spacing={2}
        justifyContent={'center'}
        alignItems={'center'}
        paddingY={'2%'}
      >
        <Typography
          fontFamily={typography.fontFamily}
          fontWeight={'600'}
          fontSize={{ lg: '2.25rem', sm: '1.9rem', xs: '1.8rem' }}
          textAlign={'center'}
        >
          Frequently asked question
        </Typography>
        <Stack
          spacing={2}
          justifyContent={'center'}
          alignItems={'center'}
          paddingY={'2%'}
        >
          <FAQCollapse
            Question='What is DesignTemplate.io?'
            Answer='DesignTemplate.io is an all-in-one design marketplace offering a range of templates including After Effects templates, Premiere Pro templates, motion graphics Videos, Graphic Design Assets and illustrations. Our library covers various categories such as intros, slideshows, wedding videos, Explainer Videos, Ol Effects, Tittles, Transitions, Backgrounds  and more.'
          />
          <FAQCollapse
            Question='How does the subscription work?'
            Answer='Our subscription service provides unlimited access to all our design and video assets. You can choose from monthly or annual plans. Subscribers can download and use any asset from our library without additional costs.'
          />
          <FAQCollapse
            Question='How does unlimited download work?'
            Answer='With our subscription, you can download as many templates, illustrations, and videos as you need. As per our user terms, learn more.'
          />
          <FAQCollapse
            Question='Can I use the assets for commercial projects?'
            Answer={
              'Yes, all our templates and assets come with a commercial license, allowing you to use them in both personal and commercial projects without any restrictions. However, some assets are labeled "Editorial use only," meaning they cannot be used for commercial purposes like advertising, movies, or other paid media.'
            }
          />
          <FAQCollapse
            Question='How can I cancel my subscription?'
            Answer="You can cancel your subscription anytime through your account settings. Once canceled, your account will automatically downgrade to a free account at the end of your current billing cycle, and you won't be billed again. As a free account holder, you will be able to access only the free video assets."
          />
          <FAQCollapse
            Question='What happens to my distributed videos after I cancel my subscription?'
            Answer='Any videos created and published while your subscription was active remain fully covered by our Terms of Service indefinitely. Once your subscription is canceled, you can no longer use the downloaded assets in new projects, but previously published videos will continue to be valid and licensed for use.'
          />
        </Stack>
      </Stack>
    </>
  );
}
