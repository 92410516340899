'use client';
import React from 'react';

import Cookies from 'js-cookie';
import { useQuery } from 'react-query';

import { useRouter } from 'next/navigation';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';

import { requestor } from 'dt-helpers/api/requestor';
import { setClarityEvent } from 'dt-helpers/set-clarity-events';
import { CollectionResponseData } from 'dt-types/response-types/collections';
import {
  deleteSingleCollection,
  getCollectionNames,
  saveToExistingCollection,
} from 'dt-utils/__api__/collections';
import CollectionDetails from '../collection-detail';
import SuggestedCollection from '../collection-detail/suggested-collection';
import { DialogDrawer } from '../dialog-drawer';

const suggetionsList = [
  'Illustration',
  'Wedding Templates',
  'Favourites',
  'After-Effects-templates',
  'Social-Media',
  'Festival',
];

const CollectionPopup = ({
  open,
  setOpen,
  id,
  setCollectionCount,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string;
  setCollectionCount?: (_count: number) => void;
}) => {
  const [collectionName, setCollectionName] = React.useState<string>('');
  const [myCollectionList, setMyCollectionList] = React.useState<
    CollectionResponseData[]
  >([]);
  const [error, setError] = React.useState<string>('');
  const router = useRouter();
  const { data, refetch } = useQuery(
    ['collections', open, Cookies.get('accesstoken')],
    getCollectionNames,
    {
      retry: false,
      enabled: !!Cookies.get('accesstoken'),
      onSuccess: (data) => {
        setMyCollectionList(data);

        if (setCollectionCount) {
          setCollectionCount(data?.length ?? 0);
        }
      },
    }
  );

  const deleteRepeatedEntries = (array1: any, array2: any, key: string) => {
    return array1.filter(
      (item: any) => !array2.some((obj: any) => obj[key] === item)
    );
  };

  const filteredSuggestionList = deleteRepeatedEntries(
    suggetionsList,
    myCollectionList,
    'title'
  );

  const [createNewCollection, setCreateNewCollection] = React.useState(false);

  const createCollection = async (collectionName: string) => {
    if (collectionName.trim() !== '') {
      try {
        await requestor.post('/collection', {
          title: collectionName,
        });
        setCollectionName('');
        setCreateNewCollection(false);
        refetch();

        setClarityEvent(`create_collection_${collectionName}`, 'confirmed');
      } catch (err: any) {
        if (err?.response?.status === 409) {
          setError('Collection name already exists');
        }
      }
    }
  };

  const saveToCollection = async (
    collectionId: string,
    collectionTitle: string
  ) => {
    await saveToExistingCollection(collectionId, id as string);
    setCollectionName(collectionTitle);
    refetch();

    setClarityEvent('saved_to_collection', 'confirmed');
  };

  const deleteCollection = async (collectionId: number) => {
    await deleteSingleCollection(collectionId, id as string);
    refetch();

    setClarityEvent('delete_collection', 'confirmed');
  };

  return (
    <>
      <DialogDrawer
        toggleDialog={() => {
          setError('');
          setCreateNewCollection(false);
          setCollectionName('');
          setOpen(false);
        }}
        dialogOpen={open}
        closeBtn
        wrapperStyle={{ position: 'relative' }}
      >
        <>
          {createNewCollection && (
            <ArrowBackIcon
              sx={{
                left: '0.5rem',
                top: '0.5rem',
                position: 'absolute',
                ':hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                setError('');
                setCreateNewCollection(false);
              }}
            />
          )}
          <Stack
            alignItems={'center'}
            minWidth={{ sm: '24.5rem', xs: '100%' }}
            padding={'2rem'}
          >
            {!createNewCollection ? (
              <Stack gap={1.3} width={{ sm: '20rem', xs: '80%' }}>
                <Typography
                  variant='h5'
                  textTransform={'capitalize'}
                  textAlign={'center'}
                >
                  {id ? 'Add To Collection' : 'My Collections'}
                </Typography>

                {data && data?.length > 0 && (
                  <Stack spacing={1}>
                    <Typography fontWeight={600}>
                      {id ? 'Quick to save' : ''}
                    </Typography>
                    <Stack
                      gap={1.3}
                      maxHeight={
                        filteredSuggestionList?.length > 0 ? '13rem' : '20rem'
                      }
                      overflow={'scroll'}
                    >
                      {myCollectionList?.length > 0 &&
                        myCollectionList?.map((collection: any) => (
                          <CollectionDetails
                            collection={collection}
                            key={collection?.id}
                            id={id}
                            deleteCollection={deleteCollection}
                            saveToCollection={saveToCollection}
                          />
                        ))}
                    </Stack>
                  </Stack>
                )}
                {filteredSuggestionList?.length > 0 && (
                  <Stack gap={1}>
                    <Typography textTransform={'capitalize'} fontWeight={600}>
                      suggetions
                    </Typography>

                    <Stack spacing={1.3}>
                      {filteredSuggestionList
                        ?.slice(0, 2)
                        ?.map((collection: string) => (
                          <SuggestedCollection
                            collection={collection}
                            createCollection={createCollection}
                            key={collection}
                          />
                        ))}
                    </Stack>
                  </Stack>
                )}
              </Stack>
            ) : (
              <Stack
                spacing={3}
                marginTop={'1rem'}
                width={{ sm: '20rem', xs: '70%' }}
              >
                <Typography variant='h5' textAlign={'center'}>
                  Create Collection
                </Typography>
                <Stack spacing={1}>
                  <label htmlFor='name'>Name</label>
                  <TextField
                    id='name'
                    placeholder='Add titles, like “templates” Or “illustration”'
                    value={collectionName}
                    onChange={(e) => setCollectionName(e.target.value)}
                  />
                  {error && <span style={{ color: 'red' }}>{error}</span>}
                </Stack>
                <hr style={{ opacity: '0.2' }} />
                {data && data?.length > 0 && (
                  <Stack
                    spacing={1}
                    maxHeight={'10rem'}
                    overflow={'scroll'}
                    paddingY={'0.5rem'}
                  >
                    <Typography fontSize={'1rem'} fontWeight={'600'}>
                      Your Collections
                    </Typography>
                    {myCollectionList?.map((item: any) => (
                      <Box
                        padding={'0.9rem'}
                        sx={{
                          borderRadius: '0.75rem',
                          border: '0.7px solid  #C2BEBE',
                          background: '#FFF',
                          ':hover': {
                            cursor: 'pointer',
                          },
                          textTransform: 'capitalize',
                        }}
                        key={item?.id}
                        onClick={() => {
                          router.push(
                            `/profile/collections/?collectionId=${item?.id}&collectionName=${item?.title}`
                          );
                        }}
                      >
                        {item?.title?.length >= 24
                          ? `${item?.title?.slice(0, 24)}..`
                          : item?.title}
                      </Box>
                    ))}
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
          <Button
            sx={{
              borderRadius: 'inherit',
              boxShadow: '0px 2px 23.3px 0px rgba(0, 0, 0, 0.15)',
              padding: '1rem',
              width: '100%',
              textAlign: 'center',
              cursor: 'pointer',
              backgroundColor: '#FFFFFF',
              ':hover': {
                backgroundColor: '#6E5BE4',
                color: 'white',
              },
            }}
            onClick={() => {
              if (createNewCollection) {
                if (collectionName.trim() !== '') {
                  createCollection(collectionName);
                } else {
                  setError('Please Enter Collection Name!..');
                }
              } else {
                setCollectionName('');
                setCreateNewCollection(true);
              }
            }}
          >
            Create New Collection
          </Button>
        </>
      </DialogDrawer>
    </>
  );
};

export default CollectionPopup;
