import { Box, Skeleton, Stack, Typography } from '@mui/material';

import { FlexBox } from 'shared-ui/components/flex-box';
import { Small } from 'shared-ui/components/typography';
import { CTASkelton } from '../cta-actions';
import { InnerBox } from '../styles';

const SideBarSkeleton = () => {
  return (
    <Stack sx={{ height: '30rem' }}>
      <InnerBox>
        <CTASkelton />
      </InnerBox>
      {/* Download Section Skeleton */}
      <InnerBox sx={{ textAlign: 'center' }}>
        <>
          <Skeleton
            sx={{ height: '40px', width: '40px', marginBottom: '8px' }}
          />
        </>
        <Box py={1}>
          <FlexBox
            position="relative"
            height="fit-content"
            alignItems="center"
            gap="0.5rem"
            flexDirection={'column'}
          >
            <FlexBox justifyContent={'center'} gap={1} flexWrap={'wrap'}>
              <Skeleton sx={{ width: '250px' }} />
            </FlexBox>
            <Small
              sx={{
                fontSize: '11px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                textTransform: 'capitalize',
                textAlign: 'center',
              }}
            >
              <Skeleton sx={{ bgcolor: 'grey.500', width: 250 }}></Skeleton>
            </Small>
          </FlexBox>
        </Box>
        <Typography
          sx={{
            fontSize: '11px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            textTransform: 'capitalize',
            textAlign: 'center',
            marginTop: '0.5rem',
            color: '#65E',
          }}
        >
          <Skeleton sx={{ bgcolor: 'grey.500', width: 100 }}></Skeleton>
        </Typography>
      </InnerBox>
    </Stack>
  );
};

export default SideBarSkeleton;
