import React from 'react';

import { useRouter } from 'next/navigation';

import RestoreTwoToneIcon from '@mui/icons-material/RestoreTwoTone';
import { CircularProgress, Typography } from '@mui/material';

import { FlexBox } from '../flex-box';
import { setClarityEvent } from 'dt-helpers/set-clarity-events';
import { themeColors } from '../../theme/theme-colors';

const CollectionDetails = ({
  collection,
  id,
  deleteCollection,
  saveToCollection,
}: {
  collection: any;
  id?: string;
  deleteCollection: (_id: number) => void;
  saveToCollection: (_collectionId: string, _collectionTitle: string) => void;
}) => {
  const { push } = useRouter();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [disabledRemove, setDisabledRemove] = React.useState<boolean>(false);
  const [disabledSave, setDisabledSave] = React.useState<boolean>(false);

  const navigate = (collectionId: string, collectionTitle: string) => {
    setIsLoading(true);
    setClarityEvent(`${collectionTitle}_collection_clicked`, 'confirmed');

    push(
      `/profile/collections/?collectionId=${collectionId}&collectionName=${collectionTitle}`
    );
  };

  return (
    <FlexBox
      width={'100%'}
      gap={1}
      padding={'0.7rem'}
      sx={{
        borderRadius: '0.75rem',
        backgroundColor: '#FFF',
        border: '0.7px solid #6E5BE4',
        ':hover': {
          cursor: 'pointer',
          backgroundColor: '#E9E5FF',
          border: ' 0.7px solid #6E5BE4',
        },
      }}
      alignItems={'center'}
      justifyContent={'space-between'}
      key={collection?.id}
      onClick={() => {
        !id && navigate(collection?.id, collection?.title);
      }}
    >
      <FlexBox
        width={'100%'}
        justifyContent={'space-between'}
        alignItems={'center'}
        gap={1}
      >
        <FlexBox alignItems={'center'} gap={1}>
          <RestoreTwoToneIcon />{' '}
          <Typography
            textTransform={'capitalize'}
            onClick={() => {
              id && navigate(collection?.id, collection?.title);
            }}
          >
            {collection?.title?.length >= 24
              ? `${collection?.title?.slice(0, 24)}..`
              : collection?.title}
          </Typography>
        </FlexBox>
        {isLoading && <CircularProgress size={'20px'} />}
      </FlexBox>
      {id &&
        (collection?.itemPresent ? (
          <Typography
            sx={{
              background: 'transparent',
              borderRadius: '1rem',
              fontSize: '0.8rem',
              paddingY: '0.2rem',
              color: '#E60023',
              textDecoration: 'underline',
              ':hover': {
                cursor: 'pointer',
                color: themeColors.error[900],
              },
            }}
            onClick={async () => {
              setDisabledRemove(true);
              await deleteCollection(collection?.id);
              setDisabledRemove(false);
            }}
          >
            {disabledRemove ? <CircularProgress size={'13px'} /> : 'Remove'}
          </Typography>
        ) : (
          <Typography
            sx={{
              background: 'transparent',
              borderRadius: '1rem',
              fontSize: '0.8rem',
              paddingY: '0.2rem',
              paddingRight: '0.5rem',
              color: '#6E5BE4',
              textDecoration: 'underline',
              ':hover': {
                cursor: 'pointer',
                color: themeColors.info[900],
              },
            }}
            onClick={async () => {
              setDisabledSave(true);
              await saveToCollection(collection?.id, collection?.title);
              setDisabledSave(false);
            }}
          >
            {disabledSave ? <CircularProgress size={'13px'} /> : 'Save'}
          </Typography>
        ))}
    </FlexBox>
  );
};

export default CollectionDetails;
