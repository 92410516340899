import { useState } from 'react';

import { useRouter, useSearchParams } from 'next/navigation';

import { setClarityEvent } from 'dt-helpers/set-clarity-events';
import SearchDropDown from '../drop-downlist/search-dropdown';
import { SearchBar, SearchButton, SearchIcon } from '../header-with-nav/style';
import { StyledInput } from '../home-sections/banner1/style';

const SearchBarWithCategory = () => {
  const params = useSearchParams();
  const [searchInput, setSearchInput] = useState(
    params.get('searchterm') || ''
  );
  const router = useRouter();
  const [category, setCategory] = useState<string | undefined>(
    'all categories'
  );

  return (
    <SearchBar
      name="headersearchbar"
      onSubmit={(e: any) => {
        e.preventDefault();
        e.stopPropagation();

        if (searchInput.trim().length === 0) {
          setSearchInput('');

          return;
        }

        setClarityEvent('search_button_clicked', 'confirmed');

        router.push(
          `/search?searchterm=${searchInput}&category=${category}&searchpage=1`
        );
      }}
      sx={{ paddingLeft: '0.4%', paddingRight: '0.4%' }}
    >
      <SearchDropDown setCategory={setCategory} />
      <StyledInput
        type="text"
        placeholder="Search..."
        sx={{
          fontSize: { xs: '0.6rem', md: '0.8rem' },
          paddingLeft: '0.5rem',
        }}
        value={searchInput}
        onChange={(e: any) => setSearchInput(e.target.value)}
      />
      <SearchButton type="submit" aria-label="search">
        <SearchIcon sx={{ color: 'black' }} />
      </SearchButton>
    </SearchBar>
  );
};

export default SearchBarWithCategory;
