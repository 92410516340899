import { Button, Stack, styled } from '@mui/material';

import { themeColors } from '../../theme/theme-colors';

export const HorizontalBar = styled('div')(() => ({
  borderBottom: '2px solid black',
  width: '80%',
  margin: '0 auto',
}));
export const ContinueWithGoogleBtn = styled(Button)(() => ({
  height: '46px',
  borderRadius: '9px',
  broder: '1px',
  backgroundColor: '#FDFDFD',
  display: 'flex',
  gap: '0.5rem',
  width: 'fit-content',
  borderColor: '#000',
}));

export const StyledInput = styled('input')(({ theme }) => ({
  type: 'number',
  autoComplete: 'off',
  height: '4rem',
  width: '3.5rem',
  borderColor: themeColors.primary.main,
  borderWidth: '1px',
  fontSize: '1.75rem',
  textAlign: 'center',
  ':focus': {
    outline: 'none',
  },
  '::-webkit-inner-spin-button': {
    display: 'none',
  },
}));
export const FormContainer = styled(Stack)(({ theme }) => ({
  justifyContent: 'start',
  padding: '8%',
  [theme.breakpoints.down('sm')]: { width: '100%' },
  [theme.breakpoints.up('md')]: { width: '40rem' },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));
