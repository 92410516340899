export const TRAFFIC_CHANNELS: any = {
  google: {
    regex: /google\./i,
    cpcParams: ['gclid', 'wbraid', 'gad_source'],
    organicParams: ['q'],
  },
  youtube: {
    regex: /youtube\.com|youtu\.be/i,
    cpcParams: ['gclid', 'wbraid', 'gad_source'],
    organicParams: ['v', 'search_query'],
  },
  bing: {
    regex: /bing\./i,
    cpcParams: ['bingads_cid'],
    organicParams: ['q'],
  },
  yahoo: {
    regex: /yahoo\./i,
    cpcParams: ['yclid'],
    organicParams: ['p'],
  },
  duckduckgo: {
    regex: /duckduckgo\.com/i,
    cpcParams: [],
    organicParams: ['q'],
  },
  ask: {
    regex: /ask\.com/i,
    cpcParams: [],
    organicParams: ['q'],
  },
  aol: {
    regex: /aol\.com/i,
    cpcParams: [],
    organicParams: ['q'],
  },
  baidu: {
    regex: /baidu\.com/i,
    cpcParams: [],
    organicParams: ['wd'],
  },
  yandex: {
    regex: /yandex\.com/i,
    cpcParams: [],
    organicParams: ['text'],
  },
  designtemplate: {
    regex: /designtemplate\.io/i,
    cpcParams: [],
    organicParams: ['q'],
  },
  facebook: {
    regex: /facebook\.com/i,
    cpcParams: ['fbclid'],
    organicParams: ['q'],
  },
  instagram: {
    regex: /instagram\.com/i,
    cpcParams: ['igclid'],
    organicParams: ['q'],
  },
};

export const getTrafficSourceAndMedium = (
  referrer: string,
  searchParams: URLSearchParams
) => {
  let medium;
  let source;

  if (/designtemplate\.io/i.test(referrer)) {
    source = 'direct-link';
    medium = 'organic';

    return { source, medium };
  }

  for (const key in TRAFFIC_CHANNELS) {
    if (TRAFFIC_CHANNELS[key].regex.test(referrer)) {
      source = key;
      medium = TRAFFIC_CHANNELS[key].cpcParams.some((param: any) =>
        searchParams.has(param)
      )
        ? 'cpc'
        : 'organic';
      break;
    } else {
      source = referrer;
      medium = 'Referral';
    }
  }

  return { medium, source };
};
