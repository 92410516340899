import React, { useMemo, useState } from 'react';

import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import Cookies from 'js-cookie';

import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import useDialougeToggler from '../../hooks/useDialougeToggler';
import { SocialAPIResponse } from 'dt-types/response-types/social-login';
import { requestor } from 'dt-helpers/api/requestor';
import { setClarityEvent } from 'dt-helpers/set-clarity-events';
import { typography } from '../../theme/typography';
import { FlexBoxJustify } from '../Flex/flex-box';
import GoogleIcon from '../icons/google';
import { ContinueWithGoogleBtn, HorizontalBar } from './style';

const firebaseConfig = {
  apiKey: 'AIzaSyB7T52vFvOouKacwgvoYM1HeY-JjP-JTpM',
  authDomain: 'designtemplate-auth-21572.firebaseapp.com',
  projectId: 'designtemplate-auth-21572',
  storageBucket: 'designtemplate-auth-21572.appspot.com',
  messagingSenderId: '879383420564',
  appId: '1:879383420564:web:76895972a49f9fe8db1e81',
  measurementId: 'G-85RQQZQ12B',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const Login = () => {
  const { dispatch: formDispatch } = useDialougeToggler();
  const [invalidCredentials, setInvalidCredentials] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const validateEmail = useMemo(() => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }, [email]);
  const { dispatch: dialogDispatch } = useDialougeToggler();

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvalidCredentials(false);
    setEmail(e.target.value);
  };

  const signInWithGoogle = async () => {
    setClarityEvent('signInWithGoogle_clicked', 'confirmed');

    const provider = new GoogleAuthProvider();
    const user: any = await signInWithPopup(auth, provider);

    try {
      const result: SocialAPIResponse = await requestor.post(
        '/users/social/create',
        {
          token: user.user.accessToken,
          ['social_type']: 'google',
        }
      );

      Cookies.set('accesstoken', result.data.token);
      dialogDispatch({
        type: 'TOGGLE',
        payload: {
          toggled: false,
          element: 'LoginForm',
          closeBtn: false,
        },
      });
      window.location.reload();
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const onSubmitHandler = async () => {
    try {
      setIsLoading(true);

      setClarityEvent('login_submit_clicked', 'confirmed');
      const result: any = await requestor.post('/users/login', {
        email,
      });

      const { transactionId } = result.data;

      sessionStorage.setItem(
        'verificationCredentials',
        JSON.stringify({
          transactionId,
          email,
        })
      );
      setIsLoading(false);

      formDispatch({
        type: 'TOGGLE',
        payload: {
          toggled: true,
          element: 'VerificationForm',
          closeBtn: false,
        },
      });
    } catch (error) {
      setInvalidCredentials(true);
      setIsLoading(false);
      setEmail('');
    }
  };

  return (
    <div style={{ display: 'grid', placeContent: 'center', padding: '3% 0' }}>
      <Grid
        container
        spacing={2}
        width={{ md: '22rem', sm: '15rem', xs: '22rem' }}
        justifyContent={'center'}
      >
        {invalidCredentials && (
          <Typography
            variant="body1"
            fontFamily={typography.fontFamily}
            fontSize="1rem"
            fontStyle="normal"
            fontWeight={500}
            lineHeight="normal"
            textAlign="center"
            color={'red'}
          >
            Invalid email id!
          </Typography>
        )}

        <Grid item xs={12} sx={{ position: 'relative' }}>
          <TextField
            label="Enter your email address"
            fullWidth
            variant="outlined"
            name="username"
            type="email"
            value={email}
            onChange={onChangeHandler}
            required
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            error={!validateEmail}
            color="success"
            InputLabelProps={{
              style: { zIndex: 2 },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            size={'medium'}
            fullWidth
            color={'primary'}
            onClick={onSubmitHandler}
            disabled={!validateEmail || isLoading}
          >
            {isLoading ? (
              <span>
                <CircularProgress size={'12px'} /> Sending OTP...
              </span>
            ) : (
              'Get OTP'
            )}
          </Button>
        </Grid>
      </Grid>
      <Stack spacing={2} my={'4%'}>
        <FlexBoxJustify alignItems={'center'}>
          <HorizontalBar />
          <Typography padding={'1%'}>OR</Typography>
          <HorizontalBar />
        </FlexBoxJustify>
        <ContinueWithGoogleBtn
          style={{ marginInline: 'auto' }}
          onClick={signInWithGoogle}
        >
          <GoogleIcon />
          Continue with Google
        </ContinueWithGoogleBtn>
      </Stack>
    </div>
  );
};

export default Login;
