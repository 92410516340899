import { useState } from 'react';

import {
  KeyboardArrowDownOutlined,
  Logout,
  MobileScreenShare,
  Payment,
  PersonAdd,
  ShoppingBagOutlined,
} from '@mui/icons-material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Divider, MenuItem, MenuList, Popover } from '@mui/material';


import { FlexBox } from '../flex-box';
import useAuth from '../../hooks/useAuth';


function ProfileDropDown({
  user,
  LogoutHandler,
}: {
  user: any;
  LogoutHandler: any;
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const options = [
    {
      title: 'Profile',
      source: `${process.env.USER_DASHBOARD_URL}` || '/',
      icon: <PersonIcon />,
      hideForBiggerScreen: false,
    },
    {
      title: 'Orders',
      source: `${process.env.USER_DASHBOARD_URL}/orders` || '/',
      icon: <ShoppingBagOutlined />,
      hideForBiggerScreen: false,
    },
    {
      title: 'Payment History',
      source: `${process.env.USER_DASHBOARD_URL}/payments` || '/',
      icon: <Payment />,
      hideForBiggerScreen: false,
    },
    {
      title: 'My Collections',
      source: `${process.env.USER_DASHBOARD_URL}/collections` || '/',
      icon: <CollectionsBookmarkOutlinedIcon />,
      hideForBiggerScreen: false,
    },
    {
      title: 'Refer & Earn',
      source: `${process.env.USER_DASHBOARD_URL}/refer-and-earn` || '/',
      icon: <AttachMoneyIcon />,
      hideForBiggerScreen: false,
    },
    {
      title: 'Become an affiliate',
      source: '/affiliate',
      icon: <MobileScreenShare />,
      hideForBiggerScreen: true,
    },
    {
      title: 'Become an author',
      source: '/sell-your-content',
      icon: <PersonAdd />,
      hideForBiggerScreen: true,
    },
  ];

  const { state } = useAuth();

  return (
    <div>
      <FlexBox
        alignItems={'center'}
        gap={1}
        onClick={handleClick}
        sx={{ cursor: 'pointer' }}
      >
        <Avatar>{user.email?.[0]?.toUpperCase()}</Avatar>
        <KeyboardArrowDownOutlined sx={{ color: '#888888' }} />
      </FlexBox>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        disableScrollLock={true}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ marginTop: '0.5rem' }}
      >
        <MenuList sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}>
          {options?.map((item) => {
            item.source =
              item.title === 'Become an author' &&
              state?.user?.role?.toLowerCase() === 'author'
                ? '/author'
                : item.source;

            item.title =
              item.title === 'Become an author' &&
              state?.user?.role?.toLowerCase() === 'author'
                ? 'View DashBoard'
                : item.title;

            return (
              <MenuItem
                key={item.title}
                sx={{
                  marginTop: 0,
                  display: {
                    xs: 'block',
                    md: item.hideForBiggerScreen ? 'none' : 'block',
                  },
                }}
                onClick={handleClose}
              >
                <a
                  href={item.source}
                  style={{ display: 'flex', gap: 8, alignItems: 'center' }}
                >
                  {item?.icon}
                  {item.title}
                </a>
              </MenuItem>
            );
          })}
          <Divider />
          <MenuItem onClick={LogoutHandler} style={{ marginTop: 0 }}>
            <FlexBox gap={'1rem'} alignItems={'center'}>
              <Logout />
              Logout
            </FlexBox>
          </MenuItem>
        </MenuList>
      </Popover>
    </div>
  );
}

export default ProfileDropDown;
