'use client';

import { useEffect, useState } from 'react';

import Image from 'next/image';

import Email from '@mui/icons-material/Email';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import useDialougeToggler from '../../hooks/useDialougeToggler';
import Logo from '../Logo';
import { requestor } from 'dt-helpers/api/requestor';
import { typography } from '../../theme/typography';
import { FlexBox } from '../Flex/flex-box';
import Verification from '../Forms/verification';
import { setClarityEvent } from 'dt-helpers/set-clarity-events';


const VerificationForm = () => {
  const { dispatch: dialogDispatch } = useDialougeToggler();
  const [count, setCount] = useState(0);
  const [resent, hasResent] = useState(false);
  const sessionData: any = sessionStorage.getItem('verificationCredentials');
  const email = sessionData && JSON.parse(sessionData).email;
  const [timer, setTimer] = useState<any>(0);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (timer !== 0) {
      const timeInterval = setInterval(() => {
        setTimer((prevtimer: number) => prevtimer - 1);
      }, 1000);

      if (timer === 1 && error) {
        changeEmailHandler();
      }

      return () => {
        clearInterval(timeInterval);
      };
    }
  }, [timer, error]);

  const resendOTP = async () => {
    try {
      setClarityEvent('resend_otp_clicked', 'confirmed');

      if (count < 5 && timer === 0) {
        setTimer(30);
        const { email, transactionId }: any = JSON.parse(
          sessionStorage.getItem('verificationCredentials') ?? ''
        );

        hasResent(true);

        const result: any = await requestor.post('/users/resend-otp', {
          transactionId,
        });

        sessionStorage.setItem(
          'verificationCredentials',
          JSON.stringify({ transactionId: result.transactionId, email: email })
        );
        setCount(count + 1);
      }
    } catch (err) {
      setTimer(10);
      setError(true);
    }
  };

  const changeEmailHandler = () => {
    setClarityEvent('change_email_clicked', 'confirmed');

    dialogDispatch({
      type: 'TOGGLE',
      payload: {
        toggled: true,
        element: 'LoginForm',
        closeBtn: false,
      },
    });
  };

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
      <Stack alignItems={'center'} spacing={14} height="70dvh">
        <Stack
          spacing={3}
          alignItems={'center'}
          padding={{ lg: '0.5rem', md: '1rem', xs: '2rem' }}
        >
          <Stack spacing={3}>
            <Logo direction="column" />
            <Stack spacing={2}>
              <Typography
                variant="body1"
                fontFamily={typography.fontFamily}
                fontSize="0.75rem"
                fontStyle="normal"
                fontWeight={500}
                lineHeight="normal"
                textAlign="center"
                display="flex"
                gap="0.5rem"
                justifyContent="center"
                alignItems="center"
                sx={{ ':hover': { cursor: 'pointer' } }}
                onClick={changeEmailHandler}
              >
                <span>{email}</span>
                <Image
                  src={`${process.env.CONTENT_BASE_URL}/static/next/public/assets/Images/Popup-Images/Edit-inline.png`}
                  alt={'edit'}
                  width={14}
                  height={14}
                />
              </Typography>

              <Typography
                sx={{ fontSize: { sm: '1.7rem', xs: '1rem' } }}
                fontStyle={'normal'}
                fontFamily={typography.fontFamily}
                fontWeight={500}
                variant="h4"
                textAlign="center"
              >
                Enter Verification Code
              </Typography>

              <Typography
                variant="body1"
                fontFamily={typography.fontFamily}
                fontSize="1rem"
                fontStyle="normal"
                fontWeight={500}
                lineHeight="normal"
                textAlign="center"
                color={'#7B7B7B'}
              >
                Enter 4 digit code sent to your email.
              </Typography>
            </Stack>
          </Stack>

          <Verification />
          <Stack spacing={1.5}>
            <Typography
              variant="body1"
              fontSize="0.875rem"
              fontStyle="normal"
              fontWeight={400}
              lineHeight="normal"
              color=" #7B7B7B"
              textAlign={'center'}
            >
              Didn’t receive a Verification code
            </Typography>
            <></>

            {error ? (
              <Typography
                variant="body1"
                fontSize="0.875rem"
                fontStyle="normal"
                fontWeight={400}
                lineHeight="normal"
                color="red"
                textAlign="center"
              >
                {`Login method expired, please try again in ${timer} second${
                  timer > 1 ? 's' : ''
                }`}
              </Typography>
            ) : (
              <>
                {resent && (
                  <Typography
                    variant="body1"
                    fontSize="0.875rem"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="normal"
                    color="green"
                    textAlign="center"
                  >
                    {count === 5
                      ? 'You exhausted your limit please try back again'
                      : `You have ${5 - count} attempts left.`}
                  </Typography>
                )}
                {5 > count && (
                  <FlexBox
                    gap={1}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <span>
                      <Email sx={{ paddingTop: '0.3rem' }} />
                    </span>
                    <Typography
                      variant="body1"
                      fontFamily={typography.fontFamily}
                      fontSize="0.875rem"
                      fontStyle="normal"
                      fontWeight={500}
                      lineHeight="normal"
                      textAlign="center"
                      color={timer !== 0 ? '#7B7B7B' : '#6E5BE4'}
                      onClick={resendOTP}
                      role="button"
                      sx={{ cursor: 'pointer' }}
                    >
                      Resend OTP{' '}
                      {timer !== 0 && !error && (
                        <>
                          {' '}
                          in{' '}
                          <span style={{ color: '#6E5BE4' }}>
                            {formatTime(timer).toString()}
                          </span>
                        </>
                      )}
                    </Typography>
                  </FlexBox>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
  );
};

export default VerificationForm;
