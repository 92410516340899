import { FC } from 'react';

import { ListItem } from '@mui/material';
import List from '@mui/material/List';

import { NavLink } from '../nav-link';
import { MenuListItem, StyledNavLink } from './styles';
import { NavWithChild } from './types';

type Props = { category: NavWithChild; totalItems: number };

const CategoryList: FC<Props> = ({ category, totalItems }) => {
  const { title, child, path } = category || {};

  return (
    <List>
      {totalItems > 1 && (
        <ListItem sx={{ flexShrink: 1, padding: '0 1.25rem' }}>
          <NavLink
            href={`/${path ?? ''}`}
            style={{
              fontWeight: 600,
              display: 'block',
              width: '100%',
              height: '100%',
            }}
            prefetch={false}
          >
            {title}
          </NavLink>
        </ListItem>
      )}

      {child.map((sub, i) => (
        <MenuListItem key={sub.title + i}>
          <StyledNavLink
            href={sub.url}
            prefetch={false}
            style={{ display: 'block', width: '100%', height: '100%' }}
          >
            {sub.title}
          </StyledNavLink>
        </MenuListItem>
      ))}
    </List>
  );
};

export default CategoryList;
