'use client';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { typography } from '../../theme/typography';
import Login from '../Forms/login';
import { FormContainer } from '../Forms/style';
import Logo from '../Logo';

const LoginForm = () => {
  return (
    <FormContainer spacing={3}>
      <Logo direction='column' />
      <Stack spacing={2}>
        <Typography
          sx={{ fontSize: { sm: '1.3rem', xs: '1rem' } }}
          fontStyle={'normal'}
          fontFamily={typography.fontFamily}
          fontWeight={500}
          variant='h4'
          textAlign='center'
        >
          Welcome Back
        </Typography>

        <Typography
          variant='body1'
          fontFamily={typography.fontFamily}
          fontSize='1rem'
          fontStyle='normal'
          fontWeight={500}
          lineHeight='normal'
          textAlign='center'
          color={'#7B7B7B'}
        >
          Please enter your details or select your login method.
        </Typography>
      </Stack>
      <Login />
    </FormContainer>
  );
};

export default LoginForm;
